@media (max-width: 1170px) {
  .profile-card {
    display: none;
  }

  .wall-menu{
    display: unset;
    z-index: 700;
  }

  .create-post{
    display: none;
  }
  

  
}

.comment-input:hover {
  background-color: #f5f5f5;
} 

.comment-input:focus {
  background-color: #f5f5f5;
  outline: none;
  border: "1px solid #f5f5f5";
}

.comment-input{
  background-color: #FFFFFF;
  border: "1px solid #f5f5f5";
  outline: none;
}

@media (min-width: 1170px) {
 

  .wall-menu{
    display: none;
    
  }

  .create-post{
    display: unset;
    z-index: 700;
  }
  
}
  








