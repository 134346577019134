body {
    background-color: #fbfbfbc0;
  }
  @media (min-width: 400px) {
    main {
      padding-left: 240px;
    }

   
  }

  @media (min-width: 991px) {
    .insidebody {
        margin-left: 255px;
    }
    
  }

  @media (max-width: 991px) {
    .insidebody {
        margin-left: 5px;
    }
    
  }

  
  
  /* Sidebar */
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 58px 0 0; /* Height of navbar */
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    width: 240px;
    z-index: 600;
  }
  
  @media (max-width: 400px) {
    .sidebar {
      width: 100%;
    }
    
  }




  

