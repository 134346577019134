/* Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
    .thumbnailImg{
    width: 40%;
    }

   

} */

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) { 
    .cdcard{
        /* text-align: center; */
        }

  

     }



/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .cdcard{
    /* text-align: center; */
    }

    .allmemberheading {
        margin-left: 20px;
    }
}
   